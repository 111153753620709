/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import getBlobDuration from "get-blob-duration";
import { ref, set } from "firebase/database";

import {
  checkIfThisCurrentQuestion,
  handleVideoExtenision,
  formTimeString,
  isRetakePromptsEnabled,
  isFlagRetakePromptsEnabled
} from "mixins/helperVideoRecording";

import {
  INITIAL_DURATION
} from "configs/jobs/constants";
import { setMobile, clearFuncs } from "utils/videoClearer";
import { retry } from "mixins/helpers";
import { isEmpty } from "lodash";
import { isIOS } from "react-device-detect";
import { store } from "store";
import { processAnswers } from "store/modules/сandidates/actions";
import { getCandidateToken } from "store/modules/сandidates/selectors";
import { getIsStorageSupported } from "mixins/helperCandidate";
import handlePlayFromRef from "utils/audio";
import useInterviewStore from "store/interviewStore";
import { logErrors } from "mixins/helperLogging";

export default WrappedComponent => {
  const Wrapped = props => {
    const {
      t,
      isLastQuestion,
      jobId,
      userId,
      history,
      question,
      question: {
        key: questionId = "",
        max_duration: maxDuration = INITIAL_DURATION
      } = {},
      spentTime,
      questionNumber,
      savedAnswers,
      saveOnAmazonS3,
      saveCurrentQuestion,
      saveIsAllAnswerSaved,
      clearUploadInfo,
      clearErrors,
      retakeAnswer,
      isRecording,
      setIsRecording,
      setCashedElapsingTime,
      cashedElapsingTime,
      setUploadProgress,
      navigateToNextStep,
      db,
      showErrorModal,
      finishAndSaveRecord,
      uploadBlobToAmazonS3,
      isTestMode,
      displayAudioAnimation,
      hasAudio,
      fullVideoHasAudio,
      audioContextSupported,
      setBtnsDisabled,
      updateFirebaseData,
      questionsForCandidate,
      ...rest
    } = props;

    const currentQuestionNumber = questionNumber + 1;

    const videoPlayer = useRef();
    const videoInput = useRef();

    const [cashedVideo, setCashedVideo] = useState(null);
    const [isCompleted, setIsCompleted] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const [isShowPlayButton, setShowPlayButton] = useState(true);
    const [videoBlob, setVideoBlob] = useState(null);
    const [remoteLink, setRemoteLink] = useState(null);

    const { setIsUploading } = useInterviewStore();

    useEffect(
      () => {
        setMobile(setCashedVideo);

        return () => {
          if (videoPlayer && videoPlayer.current) {
            videoPlayer.current.removeAttribute("src");
            videoPlayer.current.load();
            videoPlayer.current.remove();
          }
          clearFuncs();
        };
      },
      []
    );
    useEffect(() => {
      if (db && userId) {
        set(ref(db, `Candidates/${userId}/blobUrl`), videoBlob ? "blobUrl" : null);
      }
    }, [userId, db, videoBlob]);

    const onFileUpload = e => {
      const result = e.target.files[0];
      setVideoBlob(result);
      if (typeof retakeAnswer === "function") retakeAnswer({ userId, jobId, questionId });
    };

    const playCachedVideo = () => {
      handlePlayFromRef(videoPlayer.current);
      videoPlayer.current.onended = () => {
        setShowPlayButton(true);
      };

      setShowPlayButton(false);
    };

    const resetVideoStatus = () => {
      setWarningMessage(null);
      setIsRecording(false);
      setIsCompleted(false);
      setVideoBlob(null);
      setCashedVideo(null);
      setRemoteLink(null);
      setShowPlayButton(false);
    };

    const setStatusVideoComplete = () => {
      setIsRecording(false);
      setIsCompleted(true);
      setShowPlayButton(true);
    };

    const setStatusVideoStart = (isRerecord = false) => {
      videoInput.current.value = null;
      videoInput.current.click();

      if (!isRerecord) {
        setCashedVideo(null);
        setRemoteLink(null);
      }

      setWarningMessage(null);
      clearUploadInfo({ userId });
      clearErrors();
      if (getIsStorageSupported()) window.sessionStorage.setItem("isRetriedAndReloaded", false);
    };

    const setStatusRemoteLink = link => {
      setRemoteLink(link);
      setIsRecording(false);
      setIsCompleted(true);
      setShowPlayButton(true);
    };

    const {
      isCurrentQuestionSavedAnswer,
      savedAnswer
    } = checkIfThisCurrentQuestion(savedAnswers, question);

    const stopRecord = useCallback(async () => {
      if (!isTestMode) setIsUploading(true);

      try {
        return new Promise(async () => {
          const duration = videoBlob && (await getBlobDuration(videoBlob));

          if (duration > (maxDuration + 2)) {
            setIsRecording(false);
            setIsCompleted(false);
            setWarningMessage(t("errors.videoTooLong", {
              answerTime: formTimeString(maxDuration * 1000)
            }));
            return;
          }

          const blobUrl = videoBlob && URL.createObjectURL(videoBlob);
          setCashedVideo(blobUrl);

          if (videoBlob) {
            setStatusVideoComplete({
              ref: videoPlayer
            });

            if (!isTestMode) {
              const mediaExtension = handleVideoExtenision(videoBlob?.name);
              const onUploadProgress = percentage => setUploadProgress(percentage);

              const callbackAfterUpload = () => {
                if (typeof updateFirebaseData === "function") {
                  updateFirebaseData({ has_uploaded_media: true });
                }
                setTimeout(() => {
                  setUploadProgress(0);
                  setIsUploading(false);
                }, 3000);
              };

              const dataToUpload = {
                jobId,
                userId,
                mediaExtension,
                questionId,
                blob: videoBlob,
                spentTime,
                callback: callbackAfterUpload,
                onUploadProgress
              };

              saveCurrentQuestion({ userId, questionCurrentCashed: dataToUpload });
              const saveFileOnS3 = () => saveOnAmazonS3(dataToUpload);

              if (Boolean(videoBlob) && typeof saveOnAmazonS3 === "function") {
                retry(saveFileOnS3, showErrorModal);
              }
            }
          }
        });
      } catch (e) {
        logErrors({
          error: new Error(`${e?.message || e} - EBML: ${typeof EBML !== "undefined"}`),
          context: { userId, jobId, questionId }
        });
        showErrorModal();

        setIsUploading(false);
      }

      return false;
    }, [videoBlob]);

    const startRecord = (isRerecord = false) => {
      setCashedElapsingTime(null);
      setStatusVideoStart(isRerecord);
      setShowPlayButton(false);
    };

    const showLoadingBtn = () => {
      setBtnsDisabled(state => ({
        ...state,
        btnStart: false,
        btnStop: false,
        btnLoading: true
      }));
    };

    const handleResetState = () => {
      setIsRecording(false);
      setIsCompleted(false);
      setUploadProgress(0);
      setIsUploading(false);
      setBtnsDisabled(state => ({
        ...state,
        btnLoading: false
      }));
    };

    const finishRecord = () => {
      if (isTestMode) {
        handleResetState();
        return history.goBack();
      }

      setIsUploading(true);
      const isFromOffshootVideo = isFlagRetakePromptsEnabled && isIOS
        && !isEmpty(savedAnswer?.remote_link) && savedAnswer?.remote_link !== null
        && !savedAnswer?.is_finished;

      const noNewUpdates = !isEmpty(savedAnswer?.remote_link) && savedAnswer?.remote_link !== null
        && savedAnswer?.is_finished && isFromOffshootVideo ? true : !videoBlob;

      const callbackFinish = () => {
        if (typeof updateFirebaseData === "function") updateFirebaseData({ has_uploaded_media: true });
        handleResetState();

        if (isFromOffshootVideo) {
          const candidateToken = getCandidateToken(store.getState());
          store.dispatch(processAnswers({ userId, token: candidateToken }));
        }
      };

      if (isFromOffshootVideo || noNewUpdates ||
        (isRetakePromptsEnabled && !isTestMode && !isIOS)) {
        finishAndSaveRecord(callbackFinish, currentQuestionNumber);
        return true;
      }

      const callbackWithNavaigate = ({ response }) => {
        const newSavedAnswers = [
          ...savedAnswers?.filter(
            answer => answer.question.key !== question.key
          )
        ];

        const finishedAnswers = newSavedAnswers?.filter(
          answer => answer?.is_finished && answer?.remote_link
        );

        const isRemainingQuestion = questionsForCandidate?.length - 1 === finishedAnswers?.length ||
          currentQuestionNumber === questionsForCandidate?.length;

        const shouldGoToPreview = response?.remote_link
          && response?.is_finished && isRemainingQuestion;

        if (typeof updateFirebaseData === "function") {
          updateFirebaseData({ has_uploaded_media: true })
            .then(() => callbackFinish())
            .then(() => navigateToNextStep(false, shouldGoToPreview));
        } else {
          callbackFinish();
          navigateToNextStep(false);
        }
      };

      callbackWithNavaigate();

      return true;
    };

    useEffect(() => {
      if (videoBlob) {
        retry(stopRecord, showErrorModal);
      }
    }, [videoBlob]);

    // Check if all video answers was added
    useEffect(() => {
      if (isLastQuestion && typeof saveIsAllAnswerSaved === "function"
        && !isFlagRetakePromptsEnabled) {
        saveIsAllAnswerSaved({ userId, isAllAnsweredSaved: true });
        history.push({
          pathname: `/candidate/video-questions/preview/${userId}`,
          state: {
            jobId
          }
        });
      }
    }, [isLastQuestion, history, userId, jobId, saveIsAllAnswerSaved]);

    useEffect(() => {
      if (savedAnswers?.length > 0) {
        if (isCurrentQuestionSavedAnswer
          && !isEmpty(savedAnswer?.remote_link) && savedAnswer?.remote_link) {
          return setStatusRemoteLink(savedAnswer?.remote_link);
        }

        return resetVideoStatus();
      }

      return resetVideoStatus();
    }, [savedAnswers, questionId]);

    return (
      <WrappedComponent
        t={t}
        isRecording={isRecording}
        isCompleted={isCompleted}
        isShowPlayButton={isShowPlayButton}
        question={question}
        spentTime={spentTime}
        cashedVideo={cashedVideo}
        savedAnswers={savedAnswers}
        warningMessage={warningMessage}
        currentQuestionNumber={currentQuestionNumber}
        videoBlob={videoBlob}
        remoteLink={remoteLink}
        maxDuration={maxDuration}
        startRecord={startRecord}
        finishRecord={finishRecord}
        playCachedVideo={playCachedVideo}
        onFileUpload={onFileUpload}
        videoPlayer={videoPlayer}
        videoInput={videoInput}
        cashedElapsingTime={cashedElapsingTime}
        setCashedElapsingTime={setCashedElapsingTime}
        navigateToNextStep={navigateToNextStep}
        showErrorModal={showErrorModal}
        isTestMode={isTestMode}
        hasAudio={hasAudio}
        displayAudioAnimation={displayAudioAnimation}
        audioContextSupported={audioContextSupported}
        showLoadingBtn={showLoadingBtn}
        {...rest}
      />
    );
  };

  Wrapped.defaultProps = {
    isLastQuestion: undefined,
    history: undefined,
    jobId: undefined,
    userId: undefined,
    questionNumber: undefined,
    question: undefined,
    savedAnswers: [],
    saveOnAmazonS3: undefined,
    saveIsAllAnswerSaved: undefined,
    saveCurrentQuestion: undefined,
    clearUploadInfo: undefined,
    clearErrors: undefined,
    retakeAnswer: undefined,
    isRecording: undefined,
    setIsRecording: undefined,
    setCashedElapsingTime: undefined,
    cashedElapsingTime: undefined,
    finishAndSaveRecord: undefined,
    uploadBlobToAmazonS3: undefined,
    isTestMode: undefined,
    db: undefined,
    setBtnsDisabled: undefined,
    updateFirebaseData: undefined,
    questionsForCandidate: undefined
  };

  Wrapped.propTypes = {
    t: PropTypes.func.isRequired,
    isLastQuestion: PropTypes.bool,
    history: PropTypes.shape({
      goBack: PropTypes.func,
      push: PropTypes.func,
      length: PropTypes.number
    }),
    jobId: PropTypes.string,
    userId: PropTypes.string,
    questionNumber: PropTypes.number,
    question: PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      max_duration: PropTypes.number.isRequired,
      max_retakes: PropTypes.number
    }),
    spentTime: PropTypes.number.isRequired,
    savedAnswers: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        remote_link: PropTypes.string
      })
    ),
    saveOnAmazonS3: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    saveIsAllAnswerSaved: PropTypes.func,
    saveCurrentQuestion: PropTypes.func,
    clearUploadInfo: PropTypes.func,
    clearErrors: PropTypes.func,
    retakeAnswer: PropTypes.func,
    isRecording: PropTypes.bool,
    setIsRecording: PropTypes.func,
    setCashedElapsingTime: PropTypes.func,
    cashedElapsingTime: PropTypes.number,
    setUploadProgress: PropTypes.func.isRequired,
    navigateToNextStep: PropTypes.func.isRequired,
    db: PropTypes.shape({}),
    showErrorModal: PropTypes.func.isRequired,
    finishAndSaveRecord: PropTypes.func,
    uploadBlobToAmazonS3: PropTypes.func,
    isTestMode: PropTypes.bool,
    fullVideoHasAudio: PropTypes.bool.isRequired,
    hasAudio: PropTypes.bool.isRequired,
    displayAudioAnimation: PropTypes.bool.isRequired,
    audioContextSupported: PropTypes.bool.isRequired,
    setBtnsDisabled: PropTypes.func,
    updateFirebaseData: PropTypes.func,
    questionsForCandidate: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string
      })
    )
  };

  return Wrapped;
};
